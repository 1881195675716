import './css/news.css'

function Footer(){
    return(
        <div className="footer">
            <div className='flex-center footer-text'>© 2018 STJIT . All rights reserved</div>
        </div>
    );
}

export default Footer;