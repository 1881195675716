import '../App.css'

function Details(){
    return(
        <div className='headder'>
        <h5 className='headder-text' >Ph: 08373-266343, 262075</h5>
        <h5 className='headder-text'> Fax:08373-266427 </h5>
        <h5 className='headder-text'>  CET CODE : E033</h5>

        <h5 className='headder-text'> Research Program</h5>
        <h5 className='headder-text'> Alumni</h5>
      </div>
    );
}

export default Details;